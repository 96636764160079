import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import Image from '@activebrands/core-web/components/Image';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface ThumbnailContentProps {
    type: 'image' | 'video';
    url: string;
    isTypeValueProduct: boolean;
    loading: string;
}

const thumbnailContentStyles = {
    padding: 'var(--padding-image-pdp)',
    backgroundColor: 'var(--color-bg-image)',
    objectFit: 'contain',
};

const elementStyles = {
    height: '100%',
    aspectRatio: 'var(--ratio-vertical-primary)',
    width: '100%',
    zIndex: 1,
};

const ThumbnailContent = ({ type, url, isTypeValueProduct, loading = 'lazy' }: ThumbnailContentProps) => {
    const [css] = useStyletron();

    if (type === 'image') {
        return (
            <Image
                $style={{
                    ...elementStyles,
                    ...(isTypeValueProduct && thumbnailContentStyles),
                }}
                loading={loading}
                sizes="64px"
                src={{
                    url,
                    width: [100],
                }}
            />
        );
    }

    if (type === 'video') {
        return (
            <div
                className={css({
                    ...elementStyles,
                    position: 'relative',
                })}
            >
                {/* @todo: js-file */}
                {/* eslint-disable-next-line  */}
                {/* @ts-ignore */}
                <BackgroundVideo $style={{ height: '100%' }} src={url} />
            </div>
        );
    }

    return null;
};

export default ThumbnailContent;
