import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

import PropTypes from 'prop-types';
import ThumbnailContent from 'templates/ProductPage/MediaGallery/ThumbnailContent';
import media from '@activebrands/core-web/config/media';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import fm from 'format-message';

const Wrapper = styled('div', {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    [media.min['desktop.sm']]: {
        bottom: '12px',
        justifyContent: 'center',
        width: 'inherit',
    },
});


const ThumbnailButton = styled('button', ({ $isActive }) => ({
    flex: 1,
    backgroundColor: $isActive ? 'var(--color-palette-off-black)' : 'var(--color-base-brand-black-25)',
    margin: '0 2px',
    transitionDuration: 'var(--duration-medium)',
    transitionTimingFunction: 'var(--ease)',


    [media.min['desktop.sm']]: {
        flex: 0,
        backgroundColor: 'transparent',
        margin: '0',
    },
}));

const SwipeIndicator = styled('div', ({ $isActive }) => ({
    height: '2px',
    backgroundColor: $isActive ? 'var(--color-palette-off-black)' : 'var(--color-base-brand-black-25)',
    transitionDuration: 'var(--duration-medium)',
    transitionTimingFunction: 'var(--ease)',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
}));

const mediaThumbnail = (isFirstActive, isSecondActive, islastChild) => ({
    display: 'none',

    [media.min['desktop.sm']]: {
        display: 'block',
        height: '60px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: '1px solid var(--color-border-thumbnail)',
        transitionDuration: 'var(--duration-medium)',
        transitionTimingFunction: 'var(--ease)',
        marginRight: isFirstActive ? '-2px' : '4px',
        marginLeft: isSecondActive ? '-2px' : '4px',
        borderRadius: isFirstActive && !islastChild ? '8px 0 0 8px' : isSecondActive ? '0 8px 8px 0' : '8px',
        borderLeft: isFirstActive
            ? '1px solid var(--color-border-thumbnail-active)'
            : '1px solid var(--color-border-thumbnail)',
        borderRight:
            isSecondActive || (isFirstActive && islastChild)
                ? '1px solid var(--color-border-thumbnail-active)'
                : '1px solid var(--color-border-thumbnail)',
        borderTop:
            isFirstActive || isSecondActive
                ? '1px solid var(--color-border-thumbnail-active)'
                : '1px solid var(--color-border-thumbnail)',
        borderBottom:
            isFirstActive || isSecondActive
                ? '1px solid var(--color-border-thumbnail-active)'
                : '1px solid var(--color-border-thumbnail)',
    },
});

const zoomThumbnail = isFirstActive => {
    const activeBorder = '1px solid var(--color-border-thumbnail-active)';
    const inactiveBorder = '1px solid var(--color-border-thumbnail)';
    return {
        width: '64px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: isFirstActive ? activeBorder : inactiveBorder,
        borderRadius: '8px',
        margin: '4px 0px',
    };
};

const Thumbnails = ({ $style = {}, items = [], slider = {}, isZoomGallery = false }) => {
    const [css] = useStyletron();
    const { index } = useSlider(slider, ['index', 'isInfinite', 'numberOfSlides', 'slidesPerView']);

    if (items.length < 2) {
        return null;
    }

    return (
        <Wrapper style={{ ...$style }}>
            {items.map(({ url, type, typeValue }, i) => {
                const isTypeValueProduct = typeValue?.toLowerCase() === 'product';
                const isSecondActive = index + 1 === i;
                const isFirstActive = index === i;
                const isLastChild = i === items.length - 1;

                return (
                    <ThumbnailButton
                        key={url}
                        aria-label={fm(`Navigate to Thumbnail ${i}`)}
                        type="button"
                        $isActive={isFirstActive}
                        onClick={() => slider.slideTo(i, true)}
                    >
                        <SwipeIndicator $isActive={isFirstActive} />
                        <div
                            className={css({
                                ...(isZoomGallery
                                    ? zoomThumbnail(isFirstActive)
                                    : mediaThumbnail(isFirstActive, isSecondActive, isLastChild)),
                            })}
                        >
                            <ThumbnailContent type={type} url={url} isTypeValueProduct={isTypeValueProduct} loading="lazy" />
                        </div>
                    </ThumbnailButton>
                );
            })}
        </Wrapper>
    );
};

Thumbnails.propTypes = {
    $style: PropTypes.object,
    isZoomGallery: PropTypes.bool,
    items: PropTypes.array.isRequired,
    slider: PropTypes.object.isRequired,
};

export default Thumbnails;
